@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap);
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #f7f6f5;
}

.no-cont {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #f7f6f5;
}
.login-class {
  margin: 0 !important;
}

.content {
  margin-left: 64px;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiBadge-badge {
  border-radius: 5px !important;
}

.search > input {
  padding: 0 !important;
}

.search > input::placeholder {
  color: #453834 !important;
  opacity: 1;
}

.lang-switcher {
  font-weight: 500;
  margin-left: 3px;
  font-size: 14px;
}

.pro-sidebar.collapsed {
  width: 64px !important;
  min-width: 64px !important;
}

.MuiSwitch-switchBase.Mui-checked {
  color: #34ae1a !important;
}
.Mui-checked + .MuiSwitch-track {
  background-color: rgba(0, 0, 0, 0.38) !important;
}

.MuiDialog-paperFullScreen {
  background: #f7f6f5 !important;
}

header.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.mui-fixed {
  box-shadow: 2px 2px 6px #ddd;
}

.signup-div .MuiPaper-root.MuiPaper-elevation {
  margin-top: 10px !important;
}

.detail-tab {
  text-transform: capitalize !important;
}
.css-falldt-MuiTableCell-root {
  border-bottom: none !important;
}

.signup-div .fs-dialog-appbar .MuiPaper-root.MuiPaper-elevation {
  margin-top: 0px !important;
}

.Mui-focused {
  color: unset !important;
}

.Mui-disabled > .MuiStepIcon-root {
  /* color: #f7f6f5 !important; */
}

.Mui-completed {
  color: #34ae1a !important;
}

.MuiStepIcon-root.Mui-active {
  color: #4e73cd !important;
}

.css-1960bxs-MuiTypography-root {
  font-size: 0.9rem !important;
}

.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  margin-top: 0px !important;
}

.panella-header > .Mui-expanded.MuiAccordionSummary-contentGutters {
  margin: 0px !important;
}

.panella-header {
  min-height: 0px !important;
}

.MuiAccordion-region > .MuiAccordionDetails-root {
  padding: 0px 16px;
  width: 90%;
}
.MuiAccordion-region .MuiInputBase-root {
  width: 100%;
}

.filter-select {
  width: 100%;
}

.panella-header > .Mui-expanded > p {
  color: #eb603a;
  padding: 5 px 0px;
}

.panella-header > .Mui-expanded {
  background-color: #fdf4f5;
}

.customDatePicker input {
  padding: 11px 14px !important;
  font-size: 0.8rem !important;
  border-radius: 2px !important;
}

.customDatePicker > div.MuiInputBase-adornedEnd {
  border-radius: 2px !important;
  color: #453834 !important;
}

.customDatePicker fieldset {
  border-color: rgba(85, 56, 48, 0.15) !important;
}

.actionListMenu > .MuiMenu-paper {
  box-shadow: 0px 0px 1px rgba(85, 56, 48, 0.15) !important;
  border-radius: 0px;
  border: 1px solid;
  border-color: rgba(85, 56, 48, 0.15) !important;
}

.filterApply {
  background-color: #eb603a !important;
  border-color: #eb603a !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 2px !important;
}

.filterApply:disabled {
  background-color: #eb603a !important;
  border-color: #eb603a !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 2px !important;
  opacity: 0.5;
}

.filterReset {
  background-color: #f7f6f5 !important;
  border-color: rgba(85, 56, 48, 0.15) !important;
  color: #453834 !important;
  text-transform: capitalize !important;
  border-radius: 2px !important;
}

.stock-table > td {
  border-bottom: none !important;
  border: 1px solid #e0e0e0 !important;
}

.prod-title {
  white-space: normal !important;
}

.MuiDataGrid-panelWrapper
  > .MuiDataGrid-panelContent
  > .MuiDataGrid-columnsPanel
  > div:last-child {
  display: none;
}

.fbmmProdList:not(:last-child) {
  border-bottom: 1px solid #453834 !important;
}

.signup-div .MuiPaper-root.MuiPaper-elevation {
  margin-top: 0px !important;
}

.signup-fc {
  width: 100% !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  height: 1.4em !important;
}

.last-grid-signup {
  padding-top: 0px !important;
  margin-top: 10px !important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  height: 1.4em !important;
}

.MuiTablePagination-toolbar .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  height: 1em !important;
}

.signup-input {
  width: 85% !important;
}

td.css-p80njf-MuiTableCell-root .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  height: 1em !important;
}

.error .MuiInputBase-input {
  border: 1px solid red !important;
}
.success .MuiInputBase-input {
  border: 1px solid green !important;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

/* circle {
  r: 24;
} */

#paymentMethodId {
  min-height: 0px !important;
  height: 12px !important;
  line-height: 12px !important;
}
#currencyId {
  min-height: 0px !important;
  height: 12px !important;
  line-height: 12px !important;
}

.no-padding {
  padding-top: 0px !important;
}

.no-padding-title {
  padding-top: 8px !important;
}
.custom-checkbox {
  border: 1px solid #45383426 !important;
  border-radius: 3px !important;
  padding-right: 15px !important;
  height: 35px !important;
}

.custom-checkbox-checked {
  background-color: #43bd230d !important;
  border: 1px solid #34ae1a !important;
  border-radius: 3px !important;
  padding-right: 15px !important;
  color: #34ae1a !important;
  height: 35px !important;
}

.custom-radio {
  border: 1px solid #45383426 !important;
  border-radius: 3px !important;
  padding-right: 15px !important;
  height: 35px !important;
}

.custom-radio-checked {
  background-color: #43bd230d !important;
  border: 1px solid #34ae1a !important;
  border-radius: 3px !important;
  padding-right: 15px !important;
  color: #34ae1a !important;
  height: 35px !important;
}
.radioGroup {
  margin-left: 10px !important;
}

.mlabel {
  margin-left: 5px !important;
}

.signup-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #eb603a;
}

.setEmail .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: "#000!important";
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dynamicVariant {
}

.productPreview {
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.productPreviewGrid {
  padding-top: 0px !important;
  margin-top: 5px !important;
}

.productPreviewGroup {
  padding-bottom: 0px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}
.border-none {
  border: none !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.upload-list .MuiDataGrid-virtualScroller {
  overflow: hidden !important;
}

.alice-carousel {
  margin: unset !important;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #45383480 !important;
  width: 12px !important;
  height: 12px !important;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #d7283e !important;
  width: 12px !important;
  height: 12px !important;
}

.alice-carousel__dots {
  position: fixed !important;
  margin-left: 21% !important;
  bottom: 100px !important;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active::after {
  background-color: #d7283e !important;
  width: 12px !important;
  height: 12px !important;
}

div::-webkit-scrollbar {
  height: 7px;
}
div::-webkit-scrollbar-track {
  border-radius: 10px;

  webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
div::-webkit-scrollbar-thumb {
  background-color: a6c53b;

  webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.slick-dots li button:before {
  font-size: 15px !important;
  color: #d7283e !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  top: 1 !important;
}

@media (max-width: 960px) {
  .alice-carousel__dots {
    display: none !important;
  }
}

.MuiAutocomplete-inputRoot {
  border-radius: 0px !important;
}

button.merchantDetailTab.Mui-selected {
  color: #4e73cd !important;
  font-weight: 500 !important;
}

.merchantDetailTabContainer .MuiTabs-indicator {
  display: none !important;
}

.messageBadge span.MuiBadge-badge {
  border-radius: 100% !important;
  background-color: #d7283e !important;
}

.messageUploadFileButton {
  text-transform: none !important;
  color: #4e73cd !important;
  border-color: #45383426 !important;
}

.messageUploadFileButton:hover {
  color: #4e73cd !important;
  border-color: #45383426 !important;
  background-color: #fff !important;
}

.dialog {
  z-index: 1000;
}

.react-select-container {
  z-index: 2000; /* Dialog'dan daha yüksek bir değer */
}

/* screen max 960 */

@media (max-width: 768px) {
  .alice-carousel__dots {
    position: absolute !important;
    display: block !important;
    bottom: -80px !important;
    margin-left: 35% !important;
  }
}

@media (min-width: 900px) {
  .alice-carousel__dots {
    bottom: unset !important;
  }
}

@media (min-width: 1920px) {
  .alice-carousel__dots {
    bottom: 140px !important;
    margin-left: 21% !important;
  }
}

.change-products-dialog .MuiDialog-paper {
  max-width: 900px !important;
}

/* #react-select-4-input {
  width: 500px !important;
} */

/* css apply 4.div */
.merchant-select {
  width: 300px !important;
}

@media (max-height: 600px) {
  .loginSliderContainer,
  .login-slider {
    display: none !important;
  }
  .loginForm {
    height: 1000px !important;
    display: flex !important;

    width: 100% !important;
  }
}

